<template>
  <div>
    <ManageThesisAndResearch />
  </div>
</template>
<script>
import ManageThesisAndResearch from "@/components/ManageThesisAndResearch/ManageThesisAndResearch";
export default {
  components: {
    ManageThesisAndResearch,
  },
  created() {},
};
</script>
